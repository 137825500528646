import Link from 'next/link';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { StyledBlock } from './Voucher.styles';
import PadlockSVG from './assets/padlock.svg';
import { Float } from 'schema-dts';

import { VoucherFixedAmountParagraph } from './__generated__/VoucherFixedAmountParagraph';
import { VoucherMultipleAmountsParagraph } from './__generated__/VoucherMultipleAmountsParagraph';

export type VoucherParagraphContentType =
  | VoucherFixedAmountParagraph
  | VoucherMultipleAmountsParagraph;

export type VoucherParagraphProps = {
  content: VoucherParagraphContentType;
};

export default function Voucher({
  content,
}: {
  content: VoucherParagraphContentType | null;
}) {
  if (content) {
    return <VoucherComponent content={content} />;
  }
  return <></>;
}

function VoucherComponent({
  content,
}: {
  content: VoucherParagraphContentType;
}): ReactElement {
  const { title, description, callToAction } = content;
  const isMultiple = content.__typename === 'VoucherMultipleAmountsParagraph';
  const isFixed = content.__typename === 'VoucherFixedAmountParagraph';

  const [amount, setAmount] = useState<Float>(0);
  const [showOther, setShowOther] = useState<boolean>(false);
  const [otherInput, setOtherInput] = useState<string>('');
  const [otherInputValid, setOtherInputValid] = useState<boolean>(true);
  const otherInputRef = useRef<HTMLInputElement | null>(null);

  const handleAmountClick = (value: Float) => {
    setAmount(value);
    setShowOther(false);
    setOtherInputValid(true);
  };

  const handleOtherClick = () => {
    setShowOther(true);
    setAmount(0);
  };

  const handleOtherInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setOtherInput(value);

    if (/^[1-9]\d{0,4}((.[0-9]{1,2})?)$/.test(value)) {
      setOtherInputValid(true);
      setAmount(parseFloat(value));
    } else {
      setOtherInputValid(false);
    }
  };

  const handleCtaClick = () => {
    if (otherInputRef && !otherInputValid && otherInputRef.current) {
      otherInputRef.current.focus();
    }
  };

  useEffect(() => {
    if (isFixed) {
      setAmount(content.amount);
    } else if (isMultiple && content.singleVoucher) {
      const defaultOption = content.singleVoucher.options.find((item) => item.isDefault);
      if (defaultOption?.value) {
        setAmount(defaultOption.value);
      }
    }
  }, [content, isFixed, isMultiple]);

  const currentDescription = useMemo(() => {
    if (isMultiple && !showOther && content.singleVoucher) {
      const activeOption = content.singleVoucher.options.find((i) => i.value === amount);
      return activeOption?.description ?? null;
    }
    return description;
  }, [amount, isMultiple, showOther, description, content]);

  let voucherUrl = new URL(callToAction.url);
  voucherUrl.searchParams.append('amount', `${amount}`);

  return (
    <StyledBlock className="voucher-component">
      <div className="title">{title}</div>
      {description && <div className="description">{description}</div>}

      {isMultiple && content.singleVoucher && (
        <div className="value-select-wrapper">
          <div className="buttons-wrapper">
            {content.singleVoucher.options.map((option) => (
              <button
                key={`amount-${option.value}`}
                onClick={() => handleAmountClick(option.value)}
                className={`amount-button${option.value === amount && !showOther ? ' active' : ''}`}
              >
                <span>{option.label}</span>
              </button>
            ))}
            {content.singleVoucher.showFieldOther && (
              <button
                className={`amount-button${showOther ? ' active' : ''}`}
                onClick={handleOtherClick}
              >
                <span>Other</span>
              </button>
            )}
          </div>
        </div>
      )}

      {showOther ? (
        <>
          <label htmlFor="other_amount">
            <span className="visually-hidden">Enter voucher amount (required)</span>
            <input
              type="number"
              id="other_amount"
              className={`other-amount${!otherInputValid ? ' errors' : ''}`}
              name="other_amount"
              step={0.01}
              min={1}
              max={99999}
              placeholder="Enter an amount"
              value={otherInput}
              ref={otherInputRef}
              onChange={handleOtherInput}
            />
          </label>

          {!otherInputValid && (
            <div className="other-validation-message-wrapper" role="alert">
              <div className="other-validation-message">
                Please enter a number between 1 and 99999
              </div>
            </div>
          )}
        </>
      ) : isMultiple && currentDescription && <div className="description short">{ currentDescription }</div>}

      <div className="cta-wrapper">
        <Link href={otherInputValid ? voucherUrl.toString() : '#'}>
          <a
            className={`${!otherInputValid ? 'errors' : ''}`}
            onClick={handleCtaClick}
            target={callToAction.target || '_self'}
          >
            {callToAction.title}
            <span className="visually-hidden">. Buy a £{amount} voucher</span>
            <PadlockSVG />
          </a>
        </Link>
      </div>
    </StyledBlock>
  );
}
